"use client";

import InputLabel from "@/components/library/InputLabel";
import clsx from "clsx";
import React from "react";

export interface TextAreaProps {
  id?: string;
  value: string;
  onChange: (value: string) => void;
  label?: string | React.ReactNode;
  tooltipText?: string;
  placeholder?: string;
  "data-testid"?: string;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  hideHighlight?: boolean;
  isRequired?: boolean;
  showError?: boolean;
  helperText?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  value,
  onChange,
  label,
  tooltipText,
  isRequired,
  placeholder,
  ["data-testid"]: dataTestId,
  containerClassName,
  labelClassName,
  className,
  disabled,
  hideHighlight,
  helperText,
}) => {
  return (
    <div className={clsx(containerClassName, { "mt-2": label })}>
      {label && typeof label === "string" && tooltipText && (
        <InputLabel
          data-testid={dataTestId && `${dataTestId}_label`}
          htmlFor={id}
          value={isRequired ? `${label} *` : label}
          tooltipText={tooltipText}
        />
      )}
      {label && (typeof label !== "string" || !tooltipText) && (
        <label
          className={clsx(
            "text-sm",
            "font-medium",
            "mb-1",
            "block",
            "text-800",
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <div>
        <textarea
          id={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          placeholder={placeholder}
          className={clsx(
            "w-full",
            "rounded-xl",
            "text-sm",
            "p-2",
            "border",
            "bg-white",
            "dark:bg-gray_comp",
            "!border-gray-300",
            "dark:!border-gray-600",
            "!ring-gray-300",
            "dark:!ring-gray-600",
            "min-h-[100px]",
            {
              "bg-gray-200": disabled,
              "text-gray-700": disabled,
            },
            className
          )}
        />
      </div>
      {helperText && (
        <p className={clsx("text-sm", "text-gray-500", "dark:text-gray-400")}>
          {helperText}
        </p>
      )}
    </div>
  );
};

export default TextArea;
