"use client";

import NavContainer from "@/components/common/navContainer";
import Loading from "@/components/common/loading";
import Login from "@/components/common/login";
import { useAuthContext } from "./AuthContextProvider";
import chameleon from "@chamaeleonidae/chmln";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { SubscriptionStatus } from "@/enums/stripe";
import clsx from "clsx";

let ChameleonInitialized = false;

export const NavContainerProvider = ({
  children,
  isAdmin,
  setShowUserManagement,
}: {
  children: React.ReactNode;
  isAdmin?: boolean;
  setShowUserManagement: (value: boolean) => void;
}) => {
  const router = useRouter();
  const auth = useAuthContext();

  const [initializedUserId, setInitializedUserId] = useState<string | null>(
    null
  );
  const [zIndex, setZIndex] = useState(100);

  useEffect(() => {
    if (!auth.user || initializedUserId === auth.user.id) {
      return;
    }

    setInitializedUserId(auth.user.id);

    const userNeedsOrgSelection = auth.user && !auth.user.orgId;
    const userNeedsOnboarding = auth.user && !auth.user.agencyId;
    if (userNeedsOrgSelection || userNeedsOnboarding) {
      router.push("/springboard");
      return;
    }

    if (!ChameleonInitialized && process.env.NEXT_PUBLIC_CHAMELEON_LICENSE) {
      ChameleonInitialized = true;

      chameleon.init(process.env.NEXT_PUBLIC_CHAMELEON_LICENSE, {
        fastUrl: "https://fast.chameleon.io/",
      });
    }

    if (!isAdmin && auth.user) {
      const companyProperties = auth.user.agencyId
        ? {
            company: {
              agencyId: auth.user.agencyId,
              plan: auth.isFreeTrial
                ? "FreeTrial"
                : auth.subscription?.status === SubscriptionStatus.none
                ? "FreeTrial"
                : auth.subscription?.status,
            },
          }
        : {
            company: {
              plan: "FreeTrial",
            },
          };

      chameleon.identify(auth.user.id, {
        email: auth.user.email,
        name: auth.user.name,
        companyProperties,
      });
    }
  }, [auth.user]);

  const isOnboarded = auth.user && auth.user.agencyId && auth.user.orgId;
  let isLoading = auth.isLoading || (auth.user != undefined && !isOnboarded);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setZIndex(-50);
      }, 1800);
    }
  }, [isLoading]);

  return (
    <>
      {zIndex > 0 && (
        <div
          className={clsx(
            "h-screen",
            "w-screen",
            "fixed",
            "bg-[#333354]",
            "text-800",
            "bg-[url('/icons/circleBackgroundFull.svg')]",
            "bg-cover",
            "bg-no-repeat",
            {
              ["opacity-100"]: isLoading,
              [`opacity-0 transition-opacity ease-in !duration-[2000ms] z-[${zIndex}]`]:
                !isLoading && zIndex > 0,
            }
          )}
        />
      )}
      {isLoading && <Loading />}
      {!isLoading && (
        <NavContainer
          setShowUserManagement={setShowUserManagement}
          isAdmin={!!isAdmin}
        >
          {auth.user != undefined ? <>{children}</> : <Login />}
        </NavContainer>
      )}
    </>
  );
};
