import * as React from "react";
import clsx from "clsx";
import { Label, LabelProps, Tooltip } from "flowbite-react";
import Icon, { ICON_SIZES } from "./Icon";

export type InputLabelProps = {
  withSpacing?: boolean;
  tooltipText?: React.ReactNode;
} & LabelProps;

const InputLabel: React.FC<InputLabelProps> = ({
  withSpacing = true,
  tooltipText,
  ...props
}) => {
  if (tooltipText) {
    return (
      <Tooltip content={tooltipText} className="z-50">
        <Label
          className={clsx("inline-block", { ["mb-1"]: withSpacing })}
          {...props}
        />
        <Icon icon="circle-question" className="ml-2" size={ICON_SIZES.SM} />
      </Tooltip>
    );
  }

  return (
    <Label className={clsx("block", { ["mb-1"]: withSpacing })} {...props} />
  );
};

export default InputLabel;
