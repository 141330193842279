import clsx from "clsx";
import React from "react";
import Icon, { ICON_TYPE } from "../library/Icon";

const AlertDot: React.FC = () => {
  return (
    <Icon
      icon="period"
      type={ICON_TYPE.SOLID}
      className={clsx(
        "text-red-600",
        "top-[-5px]",
        "relative",
        "animate-pulse"
      )}
    />
  );
};

export default AlertDot;
