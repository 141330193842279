import clsx from "clsx";
import { Modal } from "flowbite-react";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";
import { User, useAuthContext } from "@/contexts/common/AuthContextProvider";
import { useAlert } from "@/hooks/useAlert";
import { BugReport, BugReportResponse } from "@/types/admin/common";
import { GlobalNavSectionType } from "@/types/common/library";
import { IsAdmin } from "@/utils/common/admin";
import { api } from "@/utils/common/api";
import { getNameWithLastInitial } from "@/utils/library/formatters";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { Popover } from "@headlessui/react";
import AlertDot from "../common/AlertDot";
import Button from "./Button";
import { ICON_SIZES, ICON_TYPE, Icon } from "./Icon";
import { NayLayoutProps } from "./NavLayout";
import { GlobalNavigationItem } from "./NavLayoutItem";
import Switch from "./Switch";
import TextArea from "./TextArea";
import UserAvatar from "./UserAvatar";

type DefaultUserActionProps = {
  label: string;
  icon: IconName;
  hasAlert?: boolean;
};

type UserActionProps = DefaultUserActionProps &
  (
    | {
        onClick: () => void;
        href?: never;
      }
    | {
        onClick?: never;
        href: string;
      }
  );

const UserAction: React.FC<UserActionProps> = ({
  label,
  onClick,
  href,
  icon,
  hasAlert,
}) => {
  return (
    <a
      onClick={onClick}
      href={href}
      className={clsx(
        "grid",
        "gap-2",
        "w-full",
        "p-2",
        "transition-all",
        "cursor-pointer",
        "hover:bg-slate-100",
        "focus:bg-slate-100",
        "active:bg-slate-100",
        "dark:hover:bg-slate-800",
        "dark:focus:bg-slate-800",
        "dark:active:bg-slate-800",
        "rounded-lg",
        {
          "grid-cols-[20px_1fr]": !hasAlert,
          "grid-cols-[20px_1fr_6px]": hasAlert,
        }
      )}
    >
      <div className="flex items-center justify-center">
        <Icon icon={icon} size={ICON_SIZES.SM} type={ICON_TYPE.LIGHT} />
      </div>
      <span className={clsx("text-sm", "mr-auto")}>{label}</span>
      {hasAlert && <AlertDot />}
    </a>
  );
};

type Props = {
  user: User;
  bottomSection?: GlobalNavSectionType;
  hasAlert: boolean;
} & Pick<NayLayoutProps, "userActions">;

const NavLayoutSidebarFooter: React.FC<Props> = ({
  user,
  userActions,
  bottomSection,
  hasAlert,
}) => {
  const { getToken } = useAuthContext();
  const { setAlert } = useAlert();
  const { t } = useTranslation("routes");
  const { t: lt } = useTranslation("login");
  const { t: ct } = useTranslation("common");

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [reportOpen, setOpenReport] = useState(false);
  const [bugReportText, setBugReportText] = useState("");
  const [markUrgent, setMarkUrgent] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  return (
    <div className={clsx("mt-auto")}>
      {bottomSection && (
        <div className={clsx("mb-4", "mx-[-1rem]")}>
          <label
            className={clsx(
              "pl-4",
              "uppercase",
              "text-600",
              "mt-6",
              "mb-2",
              "block"
            )}
          >
            {bottomSection.name}
          </label>
          <div className={clsx("grid", "self-stretch")}>
            {bottomSection.items.map((item) => (
              <GlobalNavigationItem
                key={`${item.label}_${item.icon}`}
                {...item}
              />
            ))}
          </div>
        </div>
      )}
      <Popover>
        <Popover.Button
          ref={setReferenceElement}
          className={clsx(
            "flex",
            "items-center",
            "justify-between",
            "gap-2",
            "w-full",
            "block",
            "outline-none",
            "focus:ring-px",
            "transition-all",
            "active:bg-slate-200"
          )}
          onClick={() => setOpen(!open)}
        >
          <UserAvatar img={user?.img} name={user.name} />
          <span
            className={clsx(
              "mr-auto",
              "text-sm",
              "whitespace-nowrap",
              "overflow-hidden",
              "text-ellipsis"
            )}
          >
            {getNameWithLastInitial(user?.name || "")}
          </span>
          {hasAlert && (
            <span>
              <AlertDot />
            </span>
          )}
          <span
            key="popperCaret"
            className={clsx(
              "transition-transform",
              open ? "rotate-0" : "rotate-180"
            )}
          >
            <Icon icon="caret-down" type={ICON_TYPE.SOLID} />
          </span>
        </Popover.Button>
        <Popover.Panel
          className={clsx(
            "absolute",
            "z-30",
            "bg-gray-100",
            "dark:bg-gray-900",
            "md:bg-white",
            "rounded-lg"
          )}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className={clsx("p-4")}>
            <p className="text-sm">{user?.name}</p>
            <p className={clsx("text-xs", "mt-1", "text-slate-500")}>
              {user.email}
            </p>
          </div>
          <hr />
          {!!userActions && (
            <ul className={clsx("p-2", "space-y-2")}>
              <UserAction
                label={lt("profile")}
                href="/profile"
                icon="square-user"
                hasAlert={hasAlert}
              />
              {userActions?.onUserManagementClick && (
                <UserAction
                  label={lt("userManagement")}
                  onClick={userActions.onUserManagementClick}
                  icon="people-arrows"
                />
              )}
              {IsAdmin(user) && (
                <UserAction
                  label={t("reportBug")}
                  onClick={() => setOpenReport(true)}
                  icon="bug"
                />
              )}
              {IsAdmin(user) && (
                <UserAction
                  label={t("admin")}
                  href="/admin"
                  icon="user-crown"
                />
              )}
              {userActions?.onLogoutClick && (
                <UserAction
                  label={lt("logout")}
                  onClick={userActions?.onLogoutClick}
                  icon="sign-out-alt"
                />
              )}
            </ul>
          )}
        </Popover.Panel>
      </Popover>
      <Modal show={reportOpen} onClose={() => setOpenReport(false)}>
        <Modal.Header>
          <h2>{t("reportBug")}</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-[-1rem]">
            <TextArea
              label={ct("description")}
              placeholder={t("reportBugPlaceholder")}
              value={bugReportText}
              onChange={(val) => setBugReportText(val)}
            />
          </div>
          <Switch
            label={ct("markUrgent")}
            checked={markUrgent}
            onChange={(checked) => setMarkUrgent(checked)}
            className="mt-2"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="w-full">
            <Button
              className="float-right"
              onClick={async () => {
                const apiReportResponse = await api<
                  BugReport,
                  BugReportResponse
                >(getToken, "/api/report-bug", "POST", {
                  url: window.location.href,
                  description: bugReportText,
                  urgent: markUrgent,
                });

                if (!apiReportResponse || !apiReportResponse.success) {
                  setAlert({
                    status: "error",
                    message:
                      apiReportResponse?.message || ct("errorReportingBug"),
                    secondsToClose: 10,
                  });
                  return;
                }

                setAlert({
                  status: "success",
                  message: apiReportResponse.message,
                  secondsToClose: 4,
                });

                setOpenReport(false);
                setMarkUrgent(false);
                setBugReportText("");
              }}
            >
              {t("reportBug")}
            </Button>
            <Button
              type="secondary"
              className={clsx("float-right", "mr-2")}
              onClick={() => setOpenReport(false)}
            >
              {ct("cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NavLayoutSidebarFooter;
