"use client";

import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useSelectedLayoutSegments } from "next/navigation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { BRANDS } from "@/enums/brands";
import { MessagingVerificationStatus } from "@/enums/messaging";
import { UserRoles } from "@/enums/user";
import { useProfile } from "@/hooks/useProfile";
import {
  GlobalNavItemType,
  GlobalNavSectionType,
} from "@/types/common/library";
import { MessagingVerificationStatusResponse } from "@/types/messaging";
import { apiLive } from "@/utils/common/api";
import { ClearAllCache } from "@/utils/common/cache";
import { GetBrand } from "@/utils/common/theme";
import { ICON_SIZES, Icon } from "../library/Icon";
import NavLayout from "../library/NavLayout";

const IsActive = (segments: string[], target: string, position: number = 0) => {
  return segments.length > position && segments[position] === target;
};

export default function NavContainer({
  children,
  isAdmin,
  setShowUserManagement,
}: {
  children: React.ReactNode;
  isAdmin: boolean;
  setShowUserManagement: (value: boolean) => void;
}) {
  const { t } = useTranslation("routes");
  const { ui } = useProfile();

  const { getToken, user, logout, isFreeTrial, subscription, role } =
    useAuthContext();
  const pathname = usePathname();
  const segments = useSelectedLayoutSegments();

  const [sections, setSections] = useState<GlobalNavSectionType[]>([]);
  const [sideNavMessage, setSideNavMessage] = useState<string>("");
  const [isMessagingVerified, setIsMessagingVerified] =
    useState<boolean>(false);
  const [brand, setBrand] = useState<BRANDS>(BRANDS.GAIL);

  const userActions = {
    onLogoutClick: () => {
      ClearAllCache();
      logout();
    },
    onUserManagementClick: () => {
      setShowUserManagement(true);
    },
  };

  const fetchMessagingStatus = async () => {
    const messagingStatusResponse =
      await apiLive<MessagingVerificationStatusResponse>(
        getToken,
        "/api/settings/agency/messaging-verification"
      );

    if (messagingStatusResponse) {
      setIsMessagingVerified(
        messagingStatusResponse.status ===
          MessagingVerificationStatus.CampaignApproved
      );
    }
  };

  useEffect(() => {
    fetchMessagingStatus();
    setBrand(GetBrand(window.location.host));
  }, []);

  useEffect(() => {
    if (role === UserRoles.Integration) {
      setSections([]);
      setSideNavMessage(t("integrationRole"));
      return;
    }

    setSideNavMessage("");

    const campaigns: GlobalNavItemType[] | null =
      ui.isUsingGalactus || isAdmin
        ? [
            {
              icon: "megaphone",
              label: t("campaigns"),
              href: "/outreach/campaigns",
              isActive: IsActive(segments, "campaigns", 1),
            },
          ]
        : [
            {
              icon: "megaphone",
              label: t("campaigns"),
              href: "/campaigns",
              isActive: IsActive(segments, "campaigns"),
            },
          ];

    const contacts: GlobalNavItemType[] | null =
      ui.isUsingGalactus || isAdmin
        ? [
            {
              icon: "address-card",
              label: t("contacts"),
              href: "/communications/contacts",
              isActive:
                IsActive(segments, "contacts", 1) ||
                IsActive(segments, "contact-lists", 1),
            },
          ]
        : [
            {
              icon: "address-card",
              label: t("contacts"),
              href: "/contacts",
              isActive: IsActive(segments, "contacts"),
            },
          ];

    let askGailName = t("askGail");

    switch (brand) {
      case BRANDS.BANCO_AZTECA:
        askGailName = t("askGailBazteca");
        break;
      case BRANDS.ROCKET:
        askGailName = t("askGailRocket");
        break;
      case BRANDS.SEBAS:
        askGailName = t("askGailSebas");
        break;
      case BRANDS.TELEFONICA:
      case BRANDS.MOVISTAR:
        askGailName = t("askGailTelefonica");
        break;
    }

    let main: GlobalNavSectionType = {
      name: "",
      items: [
        {
          icon: "gauge-simple-high",
          label: t("dashboard"),
          href: "/",
          isActive: segments.length === 0,
        },
        {
          icon: "brain-circuit",
          label: askGailName,
          href: "/ask/list",
          isActive: IsActive(segments, "ask"),
        },
        {
          icon: "phone",
          label: t("calls"),
          href: "/calls",
          isActive: IsActive(segments, "calls"),
        },
        ...contacts,
        ...campaigns,
      ],
    };

    let resources: GlobalNavSectionType = {
      name: t("resources"),
      position: "bottom",
      items: [
        {
          icon: "lightbulb-on",
          label: t("features"),
          href: "https://updates.lula.com/changelog",
          target: "_blank",
          dataAttributes: {
            "data-canny-link": "true",
          },
        },
        {
          icon: "headset",
          label: t("support"),
          href: "https://support.lula.com",
          target: "_blank",
        },
      ],
    };

    const scripts: GlobalNavItemType[] | null = [];

    if (ui.isUsingGalactus || isAdmin) {
      scripts.push({
        icon: "paper-plane",
        label: t("outreach"),
        href: "/outreach",
        isActive:
          (IsActive(segments, "outreach") && segments.length === 1) ||
          IsActive(segments, "use-cases", 1) ||
          IsActive(segments, "sequences", 1) ||
          IsActive(segments, "redialing-rules", 1) ||
          IsActive(segments, "scripts", 1),
      });
    } else {
      scripts.push({
        icon: "scroll",
        label: t("scripts"),
        href: "/scripts",
        isActive: IsActive(segments, "scripts"),
      });
    }

    let customizations: GlobalNavSectionType = {
      name: t("customizations"),
      items:
        role === UserRoles.User || role === UserRoles.ReadOnly
          ? []
          : [
              ...scripts,
              {
                icon: "bell-concierge",
                label: t("receptionist"),
                href: ui.isUsingGalactus
                  ? "/receptionist/handbook-new"
                  : "/receptionist/handbook",
                isActive: IsActive(segments, "receptionist"),
              },
              {
                icon: "handshake",
                label: t("partners"),
                href: "/partners",
                isActive: IsActive(segments, "partners"),
              },
              {
                icon: "gear",
                label: t("settings"),
                href: "/settings",
                isActive: IsActive(segments, "settings"),
              },
            ],
    };

    if (isMessagingVerified) {
      main.items.splice(1, 0, {
        icon: "message-sms",
        label: t("messages"),
        href: "/messages",
        isActive: IsActive(segments, "messages"),
      });
    }

    if (
      resources.items.findIndex((item) => item.href === "/try-gail") < 0 &&
      isFreeTrial &&
      !(ui.isUsingGalactus || isAdmin)
    ) {
      resources.items = [
        {
          icon: "rocket",
          label: t("tryGail"),
          href: "/try-gail",
          isActive: pathname.startsWith("/try-gail"),
        },
        ...resources.items,
      ];
    } else if (
      !isFreeTrial &&
      resources.items.findIndex((item) => item.href === "/start") < 0 &&
      !subscription.hasUsage
    ) {
      resources.items = [
        {
          icon: "rocket",
          label: t("start"),
          href: "/start",
          isActive: pathname.startsWith("/start"),
        },
        ...resources.items,
      ];
    }

    let newSections: GlobalNavSectionType[] = [main];

    if (customizations.items.length > 0) {
      newSections.push(customizations);
    }

    newSections.push(resources);

    setSections(newSections);
  }, [
    pathname,
    user,
    isFreeTrial,
    isMessagingVerified,
    subscription,
    ui.language,
    role,
    ui.isUsingGalactus,
    subscription.restrictions.account,
    brand,
    isAdmin,
  ]);

  let logoLocation: "default" | "solo" | "combined" = "default";

  switch (brand) {
    case BRANDS.GAIL:
    case BRANDS.WEESSIES:
      logoLocation = "default";
      break;
    case BRANDS.DEL_TORO:
    case BRANDS.MARIA:
    case BRANDS.ROCKET:
    case BRANDS.ARNALDO:
    case BRANDS.BANCO_AZTECA:
    case BRANDS.SEBAS:
    case BRANDS.TELEFONICA:
    case BRANDS.MOVISTAR:
      logoLocation = "solo";
      break;
    case BRANDS.DESOL:
      logoLocation = "combined";
  }

  return (
    <>
      {!!user && (
        <NavLayout
          customLogo={
            <div
              className={clsx("grid", "gap-2", "items-center", {
                "grid-cols-1": logoLocation !== "combined",
                "grid-cols-[1fr_20px_1fr]": logoLocation === "combined",
              })}
            >
              <div className="flex items-center justify-center">
                <Link prefetch={false} href="/">
                  {logoLocation !== "solo" && (
                    <Image
                      src="/icons/gail.svg"
                      alt="GAIL Logo"
                      width={115}
                      height={46}
                      priority={true}
                    />
                  )}
                  {brand === BRANDS.DEL_TORO && (
                    <Image
                      src="/icons/delToro.png"
                      alt="Del Toro Logo"
                      width={115}
                      height={46}
                      priority={true}
                    />
                  )}
                  {brand === BRANDS.MARIA && (
                    <Image
                      src="/icons/Maria.png"
                      alt="MARIA Logo"
                      width={115}
                      height={46}
                      priority={true}
                      style={{ filter: "drop-shadow(2px 4px 6px black)" }}
                    />
                  )}
                  {brand === BRANDS.ROCKET && (
                    <Image
                      src="/icons/rocket.png"
                      alt="Rocket Companies Logo"
                      width={115}
                      height={46}
                      priority={true}
                    />
                  )}
                  {brand === BRANDS.SEBAS && (
                    <div>
                      <Image
                        src="/icons/SebasMascot.png"
                        alt="Sebanda Insurance Mascot"
                        width={44}
                        height={46}
                        priority={true}
                        className="mr-2 inline-block"
                      />
                      <Image
                        src="/icons/Sebas.png"
                        alt="Sebanda Insurance Logo"
                        width={115}
                        height={46}
                        priority={true}
                        className="inline-block"
                      />
                    </div>
                  )}
                  {brand === BRANDS.TELEFONICA && (
                    <Image
                      src="/icons/Telefonica.svg"
                      alt="Telefónica Logo"
                      width={115}
                      height={46}
                      priority={true}
                    />
                  )}
                  {brand === BRANDS.MOVISTAR && (
                    <Image
                      src="/icons/Movistar.png"
                      alt="Movistar Logo"
                      width={62}
                      height={46}
                      priority={true}
                    />
                  )}
                  {brand === BRANDS.BANCO_AZTECA && (
                    <Image
                      src="/icons/BancoAzteca.svg"
                      alt="Banco Azteca Logo"
                      width={115}
                      height={46}
                      priority={true}
                    />
                  )}
                  {brand === BRANDS.ARNALDO && (
                    <Image
                      src="/icons/arnaldo.svg"
                      alt="Arny's Logo"
                      width={115}
                      height={46}
                      priority={true}
                    />
                  )}
                </Link>
              </div>
              {logoLocation === "combined" && (
                <>
                  <span className="text-center">
                    <Icon icon="x" size={ICON_SIZES.SM} />
                  </span>
                  <div className="flex items-center justify-center">
                    {brand === BRANDS.DESOL && (
                      <Image
                        src="/icons/desol.svg"
                        alt="DESOL Logo"
                        width={115}
                        height={46}
                        priority={true}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          }
          menuSections={sections}
          user={user}
          userActions={{
            onLogoutClick: userActions.onLogoutClick,
            onUserManagementClick:
              (user?.user_orgs?.length || 0) > 1
                ? userActions.onUserManagementClick
                : undefined,
          }}
          sideNavMessage={sideNavMessage}
          userHasAlert={false}
        >
          {children}
        </NavLayout>
      )}
      {!user && <>{children}</>}
    </>
  );
}
