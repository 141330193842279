import * as React from "react";
import clsx from "clsx";
import { ToggleSwitch, ToggleSwitchProps } from "flowbite-react";

export type SwitchProps = {} & ToggleSwitchProps;

const Switch: React.FC<SwitchProps> = ({ className, ...props }) => {
  return (
    <ToggleSwitch
      className={clsx(
        "[&>div]:!ring-button_primary/10",
        "[&>div]:bg-button_primary",
        {
          "[&>div]:bg-button_primary": props.checked,
        },
        {
          "[&>div]:bg-button_primary/40": !props.checked,
        },
        className
      )}
      {...props}
    />
  );
};

export default Switch;
